
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { login } from '@/guides/endpoints/auth'
import { register } from '@/guides/endpoints/push'
import guidePushSubscribe from '@/guides/pushSubscribe'
import FormInput from '@/components/FormInput.vue'
import Btn from '@/components/Btn.vue'
import Alert from '@/components/Alert.vue'
// import EnvSwitcher from '@/components/EnvSwitcher.vue'

export default defineComponent({
  name: 'Login',
  components: {
    FormInput,
    Btn,
    Alert,
    // EnvSwitcher,
  },

  setup() {
    const router = useRouter()
    const isLoading = ref<boolean>(false)
    const showAlert = ref<boolean>(false)
    const loginVisible = ref<boolean>(false)
    const username = ref<string>('')
    const password = ref<string>('')

    const showError = () => {
      showAlert.value = true

      window.setTimeout(() => {
        showAlert.value = false
      }, 5000)
    }

    const subscribePush = async () => {
      // Ask Push api.
      const isSubscribed = await guidePushSubscribe.isSubscribed()

      if (!isSubscribed) {
        const payload = await guidePushSubscribe.subscribe()

        if (payload) {
          register(payload)
        }
      }
    }

    const onSubmit = async () => {
      if (isLoading.value) {
        return
      }

      isLoading.value = true
      const success = await login(username.value, password.value)
      isLoading.value = false

      if (success) {
        subscribePush()
        return router.replace({ name: 'Home' })
      }

      showError()
    }

    const showLogin = () => {
      loginVisible.value = true
    }

    const hideLogin = () => {
      loginVisible.value = false
    }

    return {
      isLoading,
      showAlert,
      showLogin,
      hideLogin,
      onSubmit,
      username,
      password,
      loginVisible,
    }
  },
})
