
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'FormInput',
  props: {
    value: String,
    name: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: 'auto',
    },
    error: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const content = ref(props.value)
    const handleInput = () => {
      ctx.emit('update:value', content.value)
    }

    return {
      content,
      handleInput,
    }
  },
})
