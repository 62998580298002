<template>
  <div class="login">
    <Alert :show="showAlert">
      <SvgSprite symbol="icons-alert" size="0 0 14 11" />
      <span>Erreur : e-mail ou mot de passe incorrect</span>
    </Alert>

    <div class="login__background">
      <img
        class="login__background__bg"
        src="@/assets/images/login-bg.jpg"
        alt="Les Guides camp"
      />
      <img
        class="login__background__logo"
        src="@/assets/images/logo-white.svg"
        alt="Logo Les Guides"
      />
    </div>

    <transition-group name="show-login">
      <form
        v-if="loginVisible"
        class="login__form"
        @submit.prevent="onSubmit"
        :class="{ loading: isLoading }"
      >
        <div class="wrapper">
          <a href="#" class="login__form__back" @click.prevent="hideLogin">
            <SvgSprite
              class="login__form__back__icon"
              symbol="ui-arrow-right"
              size="15"
            />
            <span>Retour</span>
          </a>

          <h3 class="login__form__title mb-xs">Connexion</h3>

          <!-- <p class="text mb-xs">
          Tu es Animateur Guide ? Cette appli t’est destinée. Pour accéder à
          toutes ses fonctionnalités, tu auras besoin de tes identifiants
          SCRIBe.
        </p> -->

          <FormInput
            class="mb-xxs"
            placeholder="Identifiant SCRIBe"
            autocomplete="off"
            :required="true"
            v-model:value="username"
          />

          <FormInput
            class="password mb-xxs"
            placeholder="Mot de passe SCRIBe"
            :required="true"
            v-model:value="password"
            type="password"
          >
          </FormInput>

          <a
            class="forgot mb-xs"
            href="https://guides.prod.apizsolutions.com/apiz/login/recup"
            target="_blank"
            >Identifiant SCRIBe/Mot de passe oublié ?</a
          >

          <div class="mb-xs conditions">
            <label class="input-checkbox mb-xxs">
              <SvgSprite symbol="icons-ok" size="20 15" />
              <input required name="droit" value="accept" type="checkbox" />
              <span
                >J'accepte les
                <a
                  href="https://guides-app-v1.s3.fr-par.scw.cloud/uploads/2021/04/Mentions_legales-mars2021.pdf"
                  target="_blank"
                  class="conditions__link"
                  >Conditions Générales d'Utilisation</a
                >
                de MyGuides</span
              >
            </label>
          </div>

          <Btn>Se connecter</Btn>
        </div>
      </form>

      <div class="login__choices" v-else>
        <div class="login__choices-inner">
          <h1 class="login__choices__title">Bienvenue sur MyGuides !</h1>
          <div class="login__choices__content">
            <Btn @click="showLogin">Se connecter</Btn>
            <a
              href="https://guides.prod.apizsolutions.com/apiz/login/recup"
              class="login__choices__btn h3"
              target="_blank"
              >S'inscrire</a
            >
          </div>
        </div>

        <!-- <EnvSwitcher /> -->
      </div>
    </transition-group>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { login } from '@/guides/endpoints/auth'
import { register } from '@/guides/endpoints/push'
import guidePushSubscribe from '@/guides/pushSubscribe'
import FormInput from '@/components/FormInput.vue'
import Btn from '@/components/Btn.vue'
import Alert from '@/components/Alert.vue'
// import EnvSwitcher from '@/components/EnvSwitcher.vue'

export default defineComponent({
  name: 'Login',
  components: {
    FormInput,
    Btn,
    Alert,
    // EnvSwitcher,
  },

  setup() {
    const router = useRouter()
    const isLoading = ref<boolean>(false)
    const showAlert = ref<boolean>(false)
    const loginVisible = ref<boolean>(false)
    const username = ref<string>('')
    const password = ref<string>('')

    const showError = () => {
      showAlert.value = true

      window.setTimeout(() => {
        showAlert.value = false
      }, 5000)
    }

    const subscribePush = async () => {
      // Ask Push api.
      const isSubscribed = await guidePushSubscribe.isSubscribed()

      if (!isSubscribed) {
        const payload = await guidePushSubscribe.subscribe()

        if (payload) {
          register(payload)
        }
      }
    }

    const onSubmit = async () => {
      if (isLoading.value) {
        return
      }

      isLoading.value = true
      const success = await login(username.value, password.value)
      isLoading.value = false

      if (success) {
        subscribePush()
        return router.replace({ name: 'Home' })
      }

      showError()
    }

    const showLogin = () => {
      loginVisible.value = true
    }

    const hideLogin = () => {
      loginVisible.value = false
    }

    return {
      isLoading,
      showAlert,
      showLogin,
      hideLogin,
      onSubmit,
      username,
      password,
      loginVisible,
    }
  },
})
</script>

<style scoped lang="scss">
.login {
  @include get-all-space;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0 !important;
}

// .text {
//   text-align: center;
// }

h3 {
  text-align: center;
  color: $regal-blue;
}

form {
  background: $c-white;
  padding: 3rem 0 5rem 0;

  * {
    transition: opacity 0.3s;
  }

  &.loading {
    * {
      opacity: 0.7;
    }

    &,
    * {
      cursor: wait;
    }
  }
}

.wrapper {
  max-width: 400px;

  @include mq(s) {
    padding: 0 3.5rem;
  }
}

.password ::v-deep(input) {
  padding-right: 65px;
}

.forgot {
  display: inline-block;
  color: $tangerine;
  font-size: 12px;
  line-height: 16px;
  text-decoration: underline;
}

@include mq(s) {
  form {
    @include center-xy;
    width: 375px;
  }

  .login__background__logo {
    top: 15vh;
  }
}

.login__background {
  position: relative;
  flex: 1;
  overflow: hidden;
  transition: all 0.3s ease;
}

.login__background__bg {
  @include image-fit;
  object-position: center 70%;
}

.login__background__logo {
  @include center-xy;
  width: 155px;
  top: 15vh;
}

.login__choices {
  @include center-xy;
  width: 360px;
  text-align: center;

  @include mq($until: s) {
    display: flex;
    justify-content: center;
    top: auto;
    bottom: 0;
    left: auto;
    width: 100%;
    transform: none;
  }
}

.login__choices-inner {
  @include mq($until: s) {
    max-width: 30rem;
  }
}

.login__choices__content {
  * {
    margin-bottom: 1rem;

    @include mq(m) {
      margin-bottom: 2rem;
    }
  }
}

.login__choices__title {
  @include fira-bold;

  margin-bottom: 2rem;
  padding: 0 2rem;
  color: $c-white;
  font-size: 24px;
  line-height: 28px;

  @include mq(s) {
    color: $regal-blue;
  }

  @include mq(xxl) {
    font-size: 36px;
    line-height: 43px;
  }
}

.login__choices__btn {
  @include fira-bold;

  display: block;
  width: 100%;
  background: $rock-blue;
  color: $c-white;
  border: 0;
  text-align: center;
  padding: 1.45rem;
  cursor: pointer;
  text-transform: uppercase;

  @include mq($until: m) {
    margin-bottom: 5rem;
  }

  @include mq(m) {
    font-size: 1.4rem;
  }
}

.login__choices__btn:hover {
  opacity: 0.8;
}

.login__choices__btn:disabled,
.login__choices__btn[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}

.login__form__title {
  font-size: 2.4rem;
}

.forminput + .forminput {
  margin-bottom: 0.5rem;
}

.conditions {
  span {
    font-size: 12px;
  }
}

.conditions__link {
  color: $regal-blue;
  text-decoration: underline;
}

.login__form__back {
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  color: $tangerine;
  z-index: 10;

  transition: opacity 0.3s;

  @include mq(m) {
    &:hover {
      opacity: 0.8;

      .login__form__back__icon {
        transform: rotate(180deg) translateX(3px);
      }
    }
  }
}

.login__form__back__icon {
  margin-right: 0.8rem;
  stroke: $tangerine;
  stroke-width: 2;

  transform: rotate(180deg);
  transition: transform 0.3s;
}

.show-login-enter-active,
.show-login-leave-active {
  transition: all 0.4s ease;
}

.show-login-enter-from,
.show-login-leave-to {
  opacity: 0;

  @include mq($until: s) {
    overflow: hidden;
    padding: 0;
    height: 0;
    transform: translate(0, 80px);
  }
  @include mq(s) {
    transform: translate(-50%, -40px);
  }
}

@media screen and (max-height: 800px) {
  .login__form {
    top: 60%;
  }
}
</style>
