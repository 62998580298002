<template>
  <div class="forminput" :class="{ error }">
    <input
      v-if="autocomplete === 'off'"
      autocapitalize="off"
      autocorrect="off"
      :type="type"
      v-model="content"
      @input="handleInput"
      :placeholder="placeholder"
      :required="required"
      :name="name"
    />

    <input
      v-else
      :type="type"
      v-model="content"
      @input="handleInput"
      :placeholder="placeholder"
      :required="required"
      :name="name"
    />

    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'FormInput',
  props: {
    value: String,
    name: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: 'auto',
    },
    error: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const content = ref(props.value)
    const handleInput = () => {
      ctx.emit('update:value', content.value)
    }

    return {
      content,
      handleInput,
    }
  },
})
</script>

<style scoped lang="scss">
.forminput {
  position: relative;
  width: 100%;
}

::placeholder {
  font-size: 1.6rem;
  line-height: 1.4;
  color: $hippie-blue;
}

input {
  border: 0;
  padding: 1.05rem 1.2rem;
  background: $athens-gray !important;
  font-size: 1.6rem;
  line-height: 1.4;
  color: $hippie-blue !important;
  width: 100%;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px $athens-gray inset !important;
}
</style>
